<template>
	<div id="videoCheckIframe">
		<iframe
			id="neoCheckIframe"
			@load="iframeLoaded()"
			v-if="videoCheckLink"
			:src="videoCheckLink"
			scrolling="yes"
			allow="microphone; camera"
		/>
	</div>
</template>

<script>
import router from "@/router";
// import { useRoute } from "vue-router";
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "NeoCheckSubmitVideoID",
	props: {
		languageStrings: Object,
		languageErrorStrings: Object,
		playerState: Object,
		serverRules: Object,
		isMobile: Boolean,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			busyText: "",
			serverBusy: false,
			neoCheckTokenType: null,
			neoCheckAccessToken: null,
			neoCheckExpireTime: 0,
			videoCheckLink: null,
		};
	},
	watch: {},
	created() {
		onBeforeUnmount(() => {
			document.getElementById("neoCheckIframe").innerHTML = "";
			this.videoCheckLink = "";
		});
	},
	mounted() {
		// window.document.addEventListener("identityCheckComplete", this.handleNeoCheckCompleteEvent, false);
		let urlParams = new URLSearchParams(window.location.search);
		this.queryString = urlParams.get("neoCheckStatus");
		if (!this.queryString) this.neoCheckInitiate();
		if (this.queryString) this.handleNeoCheckCompleteEvent(this.queryString);
	},
	methods: {
		iframeLoaded() {
			console.log("NeoCheck iframeLoaded");
			// let neoCheckIframe = document.getElementById("neoCheckIframe");
			// let neoCheckbackButton = neoCheckIframe.contentWindow.getElementById("backButton");
			// neoCheckbackButton.onclick(() => {
			// 	router.push("/");
			// });
		},
		handleNeoCheckCompleteEvent(queryString) {
			this.status.ok = true;
			this.status.message = "NeoCheck Successful";
			this.eventBus.emit("updateStatus", this.status);
			router.push("/webPayCashIn");
		},
		async neoCheckInitiate() {
			// document.getElementById("neoCheckIframe").innerHTML = "";
			this.serverBusy = true;
			this.busyText = this.languageStrings.neoCheckInitiate;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			// We're cheating on this.  We *need* to supply URLs for back, success, and error - they're mandatory.  However,
			// the request will be rejected if they're not HTTPS.  So if we're running locally, which is the expected case
			// We might have to lie about our address.
			const redirectUrlBase = window.parent.location.href.startsWith("https")
				? window.parent.location.href
				: window.parent.location.href.replace("http", "https");

			let body = {
				RedirectOkUrl: `${redirectUrlBase}?neoCheckStatus=OK`,
				RedirectFailUrl: `${redirectUrlBase}?neoCheckStatus=FAIL`,
			};

			let requestUrl = new URL("/api/v1/idcheck/initiate", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.responseStatus === "AlreadyPassed" || dataJson?.responseStatus === "NotSupported") {
					this.busyText = "";
					this.serverBusy = false;
					this.status.ok = false;
					this.status.message = this.camelToTitleCase(dataJson?.responseStatus);
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				this.videoCheckLink = dataJson?.idCheckResult?.verificationUrl || "";

				// this.status.ok = true;
				// this.status.message = "Success";
				// this.eventBus.emit("updateStatus", this.status);
				this.busyText = "";
				this.serverBusy = false;
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#videoCheckIframe {
	height: calc(100vh - 190px);
}

#videoCheckIframe iframe {
	width: 100%;
	height: calc(100% + 60px);
	border: none;
	padding-bottom: 175px;
}

/* iOS specific CSS */
/* @supports (-webkit-touch-callout: none) {
	#videoCheckIframe iframe {
		
	}
} */
</style>
